<template>
  <div>
    <h1 class="page-content-title">
      <span><slot></slot></span>
    </h1>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.page-content-title {
  position: relative;
  font-size: 1.25rem;
}

.page-content-title span {
  position: relative;
  background-color: var(--ion-background-color);
  display: inline-block;
  padding-right: 1em;
  z-index: 2;
}

.page-content-title::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #054569;
  box-sizing: border-box;
  z-index: 1;
}
</style>