
import {
  IonContent,
  IonMenuButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import OnecastHeader from "@/components/Header.vue";
import OnecastPageTitle from "@/components/PageTitle.vue";

export default defineComponent({
  name: "Settings",
  components: {
    IonContent,

    IonMenuButton,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    OnecastHeader,
    OnecastPageTitle,
  },
  setup() {
    return {
      router: useRouter(),
    };
  },
  data() {
    return {};
  },
  methods: {
    navigate(route: RouteLocationRaw) {
      this.router.push(route);
    },
  },
});
