<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/notes"></ion-back-button>
        </ion-buttons>

        <ion-title>{{ noteInfo.title }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="content-wrapper"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonBackButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "Settings",
  components: {
    IonContent,

    IonBackButton,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  setup() {
    const savedNotes = ref([]);
    const route = useRoute();
    const { noteId } = route.params;

    const noteInfo = ref({ id: "", title: "", notes: "", fileId: "" });

    try {
      if (JSON.parse(localStorage.getItem("notes"))) {
        savedNotes.value = JSON.parse(localStorage.getItem("notes"));
      }
    } catch (e) {
      //ignore
    }

    savedNotes.value.find((n) => {
      if (n.id == noteId) {
        noteInfo.value = n;
      }
    });

    console.log(noteInfo);

    if (noteInfo.value.id == "") {
      noteInfo.value = {
        id: Number(new Date()),
        title: "New note",
        note: "",
        fileId: "",
      };
    }

    console.log(noteInfo);

    return {
      router: useRouter(),
      noteInfo,
    };
  },
  data() {
    return {};
  },
  methods: {},
});
</script>

<style scoped>
.content-wrapper {
  padding: 0 1em;
}
</style>