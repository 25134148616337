<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
          <!-- <ion-icon :icon="closeOutline" size="large"></ion-icon> -->
        </ion-buttons>

        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <OnecastHeader></OnecastHeader>
      <div class="content-wrapper">
        <OnecastPageTitle>Notes</OnecastPageTitle>

        <ion-list v-if="savedNotes.length">
          <ion-item-sliding v-for="note in savedNotes" :key="note.id">
            <ion-item
              class="note"
              detail="true"
              lines="full"
              @click="openNote(note.id)"
            >
              <ion-label>{{ note.title }}</ion-label>
              <ion-note slot="start">{{ note.dateFormatted }}</ion-note>
            </ion-item>
            <ion-item-options side="end">
              <ion-item-option color="danger" @click.stop="deleteNote(note.id)"
                ><ion-icon slot="icon-only" :icon="trash"></ion-icon
              ></ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
          <!-- <ion-item
            v-for="note in savedNotes"
            :key="note.id"
            class="note"
            @click="openNote(note.id)"
            detail="true"
            lines="full"
          >
            <ion-label>{{ note.title }}</ion-label>
            <ion-note slot="start">{{ note.dateFormatted }}</ion-note>
          </ion-item> -->
        </ion-list>
        <div v-else>
          <p>
            Notes taken while watching or listening to media will show up here
          </p>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonMenuButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonItem,
  IonLabel,
  IonNote,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from "@ionic/vue";
import { trash } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import OnecastHeader from "@/components/Header.vue";
import OnecastPageTitle from "@/components/PageTitle.vue";

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

export default defineComponent({
  name: "Settings",
  components: {
    IonContent,

    IonMenuButton,
    IonHeader,
    IonPage,
    IonTitle,

    IonButtons,
    IonNote,
    IonItem,
    IonLabel,
    IonList,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,

    IonToolbar,
    OnecastHeader,
    OnecastPageTitle,
  },
  setup() {
    const savedNotes = ref([]);

    const loadNotes = async () => {
      const ret = await Storage.get({ key: "notes" });
      const value = JSON.parse(ret.value);

      if (value) {
        savedNotes.value = value.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
      }
    };

    return {
      router: useRouter(),
      savedNotes,
      loadNotes,
      trash,
    };
  },
  data() {
    return {};
  },
  methods: {
    getNote(id) {
      return this.savedNotes.find((n) => {
        return n.id == id;
      });
    },
    openNote(id) {
      const note = this.getNote(id);
      this.router.push(`/media/${note.fileId}`);
    },
    async deleteNote(id) {
      this.savedNotes = this.savedNotes.filter((n) => {
        return n.id != id;
      });

      await Storage.set({
        key: "notes",
        value: JSON.stringify(this.savedNotes),
      });
    },
    ionViewWillEnter() {
      this.loadNotes();
    },
  },
});
</script>

<style scoped>
.content-wrapper {
  padding: 0 1em;
}

ion-note {
  font-size: 0.8em;
}
</style>