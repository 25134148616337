<template>
  <div id="filters" class="collapsed" :class="{ filtered: filtered }">
    <div id="filter_container">
      <div class="filter_toggle_container">
        <a id="filter_toggle" @click.stop="toggle"
          >Filter By
          <ion-icon
            :icon="chevronDownOutline"
            size="small"
            class="flippable_icon inline-icon"
          ></ion-icon
        ></a>
        <a id="filter_clear" @click.stop="clearFilter"
          ><ion-icon :icon="closeOutline" class="inline-icon"></ion-icon>
          Clear</a
        >
      </div>
      <ul class="filter_categories">
        <li>
          <a @click.stop="clearFilter">All Media</a>
        </li>
        <li class="sub_filter_container collapsed">
          <a class="sub_filter_toggle" @click.stop="toggle"
            >Channel
            <ion-icon
              :icon="chevronDownOutline"
              class="flippable_icon inline-icon"
            ></ion-icon
          ></a>
          <ul class="filter_list" style="">
            <li
              v-for="channel in channels"
              :key="channel.id"
              @click.stop="filter('channelId', channel.id)"
            >
              {{ channel.name }}
            </li>
          </ul>
        </li>
        <li class="sub_filter_container collapsed">
          <a class="sub_filter_toggle" @click.stop="toggle"
            >Series
            <ion-icon
              :icon="chevronDownOutline"
              class="flippable_icon inline-icon"
            ></ion-icon
          ></a>
          <ul class="filter_list" style="">
            <li
              v-for="seriesInfo in series"
              :key="seriesInfo.id"
              @click="filter('seriesId', seriesInfo.id)"
            >
              {{ seriesInfo.name }}
            </li>
          </ul>
        </li>
        <li class="sub_filter_container collapsed">
          <a class="sub_filter_toggle" @click.stop="toggle"
            >Speaker
            <ion-icon
              :icon="chevronDownOutline"
              class="flippable_icon inline-icon"
            ></ion-icon
          ></a>
          <ul class="filter_list" style="">
            <li
              v-for="speaker in speakers"
              :key="speaker.id"
              @click.stop="filter('speaker', speaker.name)"
            >
              {{ speaker.name }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { chevronDownOutline, closeOutline } from "ionicons/icons";
import { ref, onMounted } from "vue";
export default {
  props: ["channels", "series", "speakers", "filtered"],
  components: {
    IonIcon,
  },
  setup() {
    const filterContainer = ref();

    onMounted(() => {
      filterContainer.value = document.getElementById("filters");
    });
    return {
      chevronDownOutline,
      closeOutline,
      filterContainer,
    };
  },
  methods: {
    toggle(event) {
      event.target
        .closest("#filters, .sub_filter_container")
        .classList.toggle("collapsed");
    },
    clearFilter() {
      this.$emit("filter-media", "clear");
      this.filterContainer.classList.add("collapsed");
    },
    filter(type, id) {
      this.$emit("filter-media", type, id);
      this.filterContainer.classList.add("collapsed");
      this.filterContainer
        .querySelectorAll(".sub_filter_container")
        .forEach((c) => {
          c.classList.add("collapsed");
        });
    },
  },
};
</script>

<style  scoped>
#filters {
  position: relative;
  min-height: 50px;
  max-height: 5000px;
  background: #333333;
  overflow: auto;
  transition: 300ms ease-in-out;
}

#filters.collapsed {
  max-height: 50px;
  overflow: hidden;
}

#filter_container {
  position: relative;
  height: auto;
}

.filter_toggle_container {
  display: flex;
}

#filter_toggle,
#filter_clear {
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  font-weight: 600;
  color: #ffffff;
  padding: 0 5%;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  flex: 1;
}

#filter_clear {
  font-size: 12px;
  flex: 0 0 auto;
  display: none;
}

.filtered #filter_clear {
  display: block;
}

#filters ul {
  position: relative;
  margin: 0;
  padding: 0 5% 20px;
  background: #3a3a3a;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  list-style-type: none;
  text-transform: uppercase;
}

#filters ul li {
  padding: 1em 0.3em;
  border-bottom: 1px solid #6f6f6f;
  overflow: auto;
  min-height: 50px;
  max-height: 5000px;
  box-sizing: border-box;
  transition: 300ms ease-in-out;
  position: relative;
}

#filters ul li a {
  color: #fff;
  text-decoration: none;
  text-overflow: hidden;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.inline-icon {
  display: inline-block;
  vertical-align: middle;
}
.flippable_icon {
  transition: 0.3s;
  transform: rotateX(180deg) translate3d(0, 0, 0);
}

/* .collapsed .flippable_icon {
  transform: rotate(0deg);
} */

#filters.collapsed #filter_toggle .flippable_icon,
.sub_filter_container.collapsed .flippable_icon {
  transform: rotate(0deg);
}
#filters ul li.sub_filter_container {
  padding: 0;
}

#filters ul li.sub_filter_container.collapsed {
  max-height: 50px;
  overflow: hidden;
}

#filters ul li.sub_filter_container .sub_filter_toggle {
  padding: 1em 0.3em;
}

#filters ul.filter_list {
  padding: 0;
  margin: 5px 0 0;
  overflow: hidden;
}

#filters ul li:last-of-type {
  border: 0;
}

#filters ul.filter_list li {
  height: auto;
  min-height: 0;
  border: 0;
  font-size: 14px;
  padding: 0em 1em 1.5em 1em;
  text-transform: none;
  border-left: 3px solid #054569;
}

#filters ul.filter_list li:first-of-type {
  margin: 1em 0 0;
}

#filters ul.filter_list li:last-of-type {
  padding: 0 1em 0;
  margin: 0 0 1em;
  border-left: 3px solid #054569;
}

#filters ul.filter_list li:first-of-type:last-of-type {
  margin: 1em 0;
}
</style>