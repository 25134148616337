import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Media from '../views/Media.vue'
import MediaFile from '../views/MediaFile.vue'
import Quicklinks from '@/views/Quicklinks.vue';
import Notes from '@/views/Notes.vue';
import NoteCompose from '@/views/NoteCompose.vue';
import Settings from '@/views/Settings.vue';

import Test from '@/views/Test.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/media'
  },
  {
    path: '/media',
    name: 'Media',
    component: Media
  },
  {
    path: '/media/:mediaId',
    name: 'Media File',
    component: MediaFile
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes
  },
  {
    path: '/notes/:noteId',
    name: 'Edit Note',
    component: NoteCompose
  },
  {
    path: '/quicklinks',
    name: 'Quicklinks',
    component: Quicklinks
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
