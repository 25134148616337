
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  IonMenu,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";
import {
  closeOutline,
  playCircleOutline,
  createOutline,
  settingsOutline,
  linkOutline,
} from "ionicons/icons";

export default defineComponent({
  components: {
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const selectedMenu = ref(route.path);

    // const path = window.location.pathname.split("folder/")[1];
    // if (path !== undefined) {
    //   selectedIndex.value = appPages.findIndex(
    //     (page) => page.title.toLowerCase() === path.toLowerCase()
    //   );
    // }

    return {
      selectedMenu,
      closeOutline,
      playCircleOutline,
      createOutline,
      settingsOutline,
      linkOutline,
      router,
    };
  },
});
