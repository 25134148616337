import Api from "@/api/api.ts";

export default {
    getQuicklinks(options: object = {}) {
        const defaultOptions = {
            accountid: "fwcoc.org"
        }
        const mergedOptions = { ...defaultOptions, ...options }

        return Api.get(
            "/quicklinks",
            {
                params: mergedOptions
            }
        );
    },



};