import axios from "axios";

export default axios.create({
    baseURL: "https://media.perpetuatech.com/app/v3",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Max-Age": 86400
    }
});