<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
          <!-- <ion-icon :icon="closeOutline" size="large"></ion-icon> -->
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-icon :icon="refresh" @click="refreshFiles(true)"></ion-icon>
        </ion-buttons>

        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="contentFullscreen">
      <ion-refresher
        slot="fixed"
        @ionPull="startRefresh"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <OnecastHeader></OnecastHeader>
      <OnecastFilters
        :channels="channels"
        :series="series"
        :speakers="speakers"
        :filtered="filtered"
        @filter-media="onFilterMedia"
      ></OnecastFilters>
      <div class="content-wrapper">
        <OnecastPageTitle>Latest Media</OnecastPageTitle>

        <div
          v-for="file in filesLoaded"
          :key="file.id"
          class="file_container"
          @click="navigate('/media/' + file.uniqueId)"
        >
          <ion-img :src="file.thumbnail" class="file_image"></ion-img>
          <div class="file_content">
            <h3>{{ file.name }}</h3>
            <p class="file_speaker">{{ file.speaker }}</p>
            <p>{{ file.dateFormatted }}</p>
          </div>
          <span class="media_type">{{ file.type }}</span>
        </div>
      </div>

      <div
        v-if="filesAll.length == 0"
        class="skeleton_wrapper"
        style="position: relative"
      >
        <div
          v-for="n in 5"
          :key="n"
          class="file_container"
          style="padding-left: 3%"
        >
          <ion-thumbnail class="file_image">
            <ion-skeleton-text
              animated
              style="height: 0; padding-bottom: 56.25%"
            ></ion-skeleton-text>
          </ion-thumbnail>
          <div class="file_content">
            <h3><ion-skeleton-text animated></ion-skeleton-text></h3>
            <p class="file_speaker">
              <ion-skeleton-text animated></ion-skeleton-text>
            </p>
            <p><ion-skeleton-text animated></ion-skeleton-text></p>
          </div>
        </div>
      </div>

      <div
        v-if="filesAll.length != 0 && files.length == 0"
        class="file_container"
        style="padding-left: 3%"
      >
        No files found!
      </div>
      <ion-infinite-scroll
        @ionInfinite="infiniteLoad"
        threshold="20%"
        v-if="!infiniteDisabled"
      >
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more data…"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      <!-- <ion-button expand="block" @click="showLoading">Show Loading</ion-button> -->
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonRefresherContent,
  IonRefresher,
  IonMenuButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSkeletonText,
  loadingController,
  IonInfiniteScroll,
  IonIcon,
  IonImg,
  IonThumbnail,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import {
  add,
  closeOutline,
  menuOutline,
  chevronDownOutline,
  chevronDownCircleOutline,
  refresh,
} from "ionicons/icons";
import { defineComponent, ref, onMounted } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import MediaService from "@/api/MediaService.ts";
// import ImagesService from "@/api/ImagesService.ts";
import OnecastHeader from "@/components/Header.vue";
import OnecastPageTitle from "@/components/PageTitle.vue";
import OnecastFilters from "@/components/Filters.vue";

export default defineComponent({
  name: "Media",
  components: {
    IonContent,
    IonRefresherContent,
    IonRefresher,

    IonMenuButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSkeletonText,
    IonInfiniteScroll,
    IonIcon,
    IonImg,
    IonThumbnail,
    IonInfiniteScrollContent,
    OnecastHeader,
    OnecastPageTitle,
    OnecastFilters,
  },
  setup() {
    const contentFullscreen = ref(true);
    let localData = {
      files: [],
      channels: [],
      series: [],
      speakers: [],
    };
    try {
      localData = JSON.parse(localStorage.media);
    } catch (e) {
      //ignore
    }

    const data = ref(localData);
    const filesAll = ref(data.value.files);
    const files = ref(data.value.files);
    const filesLoaded = ref([]);
    const perPage = 10;
    const channels = ref(data.value.channels);
    const series = ref(data.value.series);
    const speakers = ref(data.value.speakers);

    const loadData = () => {
      const from = filesLoaded.value.length;
      const to = filesLoaded.value.length + perPage;
      filesLoaded.value = filesLoaded.value.concat(files.value.slice(from, to));
    };

    const getMediaFiles = async () => {
      await MediaService.getMedia({ limit: 0 }).then((response) => {
        localStorage.setItem("media", JSON.stringify(response.data));

        filesAll.value = response.data.files;
        files.value = response.data.files;
        channels.value = response.data.channels;
        series.value = response.data.series;
        speakers.value = response.data.speakers;

        loadData();
      });
    };

    // const getImagesFiles = async () => {
    //   console.log("fetch images started");
    //   await ImagesService.getImages({ limit: 0 }).then((response) => {
    //     console.log("fetch images complete");
    //     localStorage.setItem("images", JSON.stringify(response.data));
    //   });
    // };

    onMounted(async () => {
      loadData();
      await getMediaFiles();
    });

    return {
      router: useRouter(),
      contentFullscreen,
      add,
      closeOutline,
      menuOutline,
      chevronDownOutline,
      chevronDownCircleOutline,
      refresh,
      getMediaFiles,
      filesAll,
      files,
      filesLoaded,
      channels,
      series,
      speakers,
      loadData,
    };
  },
  data() {
    return {
      filtered: false,
      infiniteDisabled: false,
    };
  },
  methods: {
    async showLoading() {
      const loading = await loadingController.create({
        spinner: "dots",
        duration: 5000,
        message: "Click the backdrop to dismiss early...",
        translucent: true,
        cssClass: "custom-class custom-loading",
        backdropDismiss: true,
      });

      await loading.present();

      setTimeout(function () {
        loading.dismiss();
      }, 5000);
    },
    navigate(route: RouteLocationRaw) {
      this.router.push(route);
    },
    onFilterMedia(type: string, value: string) {
      this.filesLoaded = [];
      this.infiniteDisabled = false;
      if (type == "clear") {
        this.filtered = false;
        this.files = this.filesAll;
      } else {
        this.filtered = true;
        this.files = this.filesAll.filter((f) => {
          return f[type] == value;
        });
      }

      this.loadData();
    },
    async refreshFiles(showLoading: boolean) {
      this.onFilterMedia("clear", "");
      const loading = await loadingController.create({
        spinner: "dots",
        message: "Loading new content...",
        translucent: true,
        cssClass: "custom-class custom-loading",
        backdropDismiss: false,
      });

      if (showLoading) {
        await loading.present();
      }

      await this.getMediaFiles();

      if (showLoading) {
        setTimeout(function () {
          loading.dismiss();
        }, 1000);
      }
    },

    startRefresh() {
      this.contentFullscreen = false;

      const refresher = document.querySelector("ion-refresher");
      if (refresher !== null) {
        const refreshInterval = setInterval(async () => {
          if (this.contentFullscreen || (await refresher.getProgress()) == 0) {
            this.contentFullscreen = true;
            clearInterval(refreshInterval);
          }
        }, 300);
      }
    },

    async doRefresh(event: any) {
      await this.refreshFiles(false);
      this.contentFullscreen = true;
      event.target.complete();
    },

    infiniteLoad(event: any) {
      if (this.filesLoaded.length < this.files.length) {
        this.loadData();
        event.target.complete();
      } else {
        //event.target.disabled = true;
        this.infiniteDisabled = true;
      }
    },
  },
});
</script>

<style scoped>
ion-icon {
  font-size: 24px;
}
.content-wrapper {
  padding: 0 1em;
}

.file_container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 3%;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.file_image {
  width: 40%;
  height: auto;
}

.file_content {
  position: relative;
  width: 60%;
  padding: 0 3%;
  box-sizing: border-box;
}

.file_content h3 {
  font-size: 1.2em;
  font-weight: normal;
  margin: 0;
  line-height: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file_content p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
}
.file_content p.file_speaker {
  font-weight: bold;
}

.file_container .media_type {
  position: absolute;
  bottom: 2px;
  right: 5px;
  text-transform: capitalize;
  font-style: italic;
  font-size: 0.8em;
}
</style>