import { createApp } from 'vue'

import VueApp from './App.vue'
import router from './router'
//import store from "./store"

import { IonicVue } from '@ionic/vue';

import { Plugins } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const { App, SplashScreen } = Plugins;
SplashScreen.show({
  showDuration: 2000,
  autoHide: true,
});

App.addListener('appStateChange', (state) => {

  if (!state.isActive) {
    // ignore
  }
})


const app = createApp(VueApp)
  .use(IonicVue)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');

});