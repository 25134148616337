<template>
  <ion-menu side="start" menu-id="first" type="push" contentId="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Fifth Ward Church of Christ</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            router-direction="root"
            router-link="/media"
            lines="full"
            detail="false"
            :class="{ selected: selectedMenu == '/media' }"
            @click="selectedMenu = '/media'"
          >
            <ion-icon
              slot="start"
              :icon="playCircleOutline"
              class="menu-icon"
            ></ion-icon>
            <ion-label>Media</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            router-direction="root"
            router-link="/notes"
            lines="full"
            detail="false"
            :class="{ selected: selectedMenu == '/notes' }"
            @click="selectedMenu = '/notes'"
          >
            <ion-icon
              slot="start"
              :icon="createOutline"
              class="menu-icon"
            ></ion-icon>
            <ion-label>Notes</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="false">
          <ion-item
            router-direction="root"
            router-link="/quicklinks"
            lines="full"
            detail="false"
            :class="{ selected: selectedMenu == '/quicklinks' }"
            @click="selectedMenu = '/quicklinks'"
          >
            <ion-icon
              slot="start"
              :icon="linkOutline"
              class="menu-icon"
            ></ion-icon>
            <ion-label>Quicklinks</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  IonMenu,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";
import {
  closeOutline,
  playCircleOutline,
  createOutline,
  settingsOutline,
  linkOutline,
} from "ionicons/icons";

export default defineComponent({
  components: {
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const selectedMenu = ref(route.path);

    // const path = window.location.pathname.split("folder/")[1];
    // if (path !== undefined) {
    //   selectedIndex.value = appPages.findIndex(
    //     (page) => page.title.toLowerCase() === path.toLowerCase()
    //   );
    // }

    return {
      selectedMenu,
      closeOutline,
      playCircleOutline,
      createOutline,
      settingsOutline,
      linkOutline,
      router,
    };
  },
});
</script>

<style scoped>
ion-menu ion-title {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}
ion-item.selected {
  --color: var(--ion-color-primary);
}
ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}
.menu-icon {
  margin-right: 0.5em;
}
</style>