<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
          <!-- <ion-icon :icon="closeOutline" size="large"></ion-icon> -->
        </ion-buttons>

        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <OnecastHeader></OnecastHeader>
      <div class="content-wrapper">
        <OnecastPageTitle>Settings</OnecastPageTitle>
        <ion-button @click="navigate('/media')">Go to detail</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonMenuButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import OnecastHeader from "@/components/Header.vue";
import OnecastPageTitle from "@/components/PageTitle.vue";

export default defineComponent({
  name: "Settings",
  components: {
    IonContent,

    IonMenuButton,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    OnecastHeader,
    OnecastPageTitle,
  },
  setup() {
    return {
      router: useRouter(),
    };
  },
  data() {
    return {};
  },
  methods: {
    navigate(route: RouteLocationRaw) {
      this.router.push(route);
    },
  },
});
</script>

<style scoped>
.content-wrapper {
  padding: 0 1em;
}
</style>