import Api from "@/api/api.ts";

export default {
    getMedia(options: object = {}) {
        const defaultOptions = {
            limit: 0,
            accountid: "fwcoc.org",
            data: "files,channels,series,speakers"
        }
        const mergedOptions = { ...defaultOptions, ...options }

        return Api.get(
            "/media",
            {
                params: mergedOptions
            }
        );
    },



};


// export default () => {
//     const options = {
//         params: {
//             limit: 10,
//             accountid: "hcbc.com"
//         }
//     }
//     return Api.get(
//         "/media",
//         options
//     );

// };

// could also do this, which would require the import to use { getMedia }
//
// export function getMedia() {
//     const options = {
//         params: {
//             limit: "0",
//             accountid: "fwcoc.org"
//         }
//     }
//     return Api.get(
//         "/media",
//         options
//     );
// }