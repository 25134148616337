<template>
  <div>
    <div
      class="media_container"
      @click="playInit"
      :style="{ 'background-image': 'url(' + thumbnail + ')' }"
      v-if="playerType == 'native'"
    >
      <video :poster="thumbnail" playsinline="true" v-if="mediaType == 'video'">
        <source :src="url" :type="mimeType" />
      </video>
      <audio :poster="thumbnail" playsinline="true" v-if="mediaType == 'audio'">
        <source :src="url" :type="mimeType" />
      </audio>

      <ion-icon
        :icon="play"
        class="play_icon"
        v-if="!isPlaying"
        @click="playMedia"
      ></ion-icon>
    </div>
    <div class="media_container" v-if="playerType == 'external'">
      <iframe
        :src="url"
        frameborder="0"
        allowfullscreen
        allowTransparency="true"
        :style="{ 'background-image': 'url(' + thumbnail + ')' }"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { IonIcon } from "@ionic/vue";
import { play } from "ionicons/icons";
import { BackgroundMode } from "@ionic-native/background-mode";

export default defineComponent({
  props: ["thumbnail", "url", "playerType", "mediaType", "mimeType"],
  components: {
    IonIcon,
  },
  setup() {
    const state = reactive({
      hasPlayed: false,
      isPlaying: false,
      element: null,
    });

    function playInit() {
      const element = state.element;

      if (element && !element.playing && !state.hasPlayed) {
        state.hasPlayed = true;
        element.setAttribute("controls", "");
        element.play();
        BackgroundMode.enable();
      }
    }

    function playMedia() {
      if (state.element && !state.element.playing) {
        state.element.play();
      } else if (state.element && state.element.playing) {
        state.element.stop();
      }
    }

    function stopMedia() {
      if (state.element && !state.element.playing) {
        state.element.stop();
      }
    }

    onMounted(() => {
      const element = document
        .querySelector(".media_container")
        .querySelector("video, audio");
      state.element = element;

      if (element) {
        element.addEventListener("play", () => {
          state.isPlaying = true;
        });
        element.addEventListener("pause", () => {
          state.isPlaying = false;
        });
      }
    });

    return {
      ...toRefs(state),
      play,
      playInit,
      playMedia,
      stopMedia,
    };
  },
  methods: {},
});
</script>

<style scoped>
.media_container {
  position: relative;
  padding-bottom: 56.2%;
  background-position: center center;
  background-size: cover;
}

video,
audio,
iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

audio,
iframe {
  background-position: center center;
  background-size: cover;
}

ion-icon.play_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 4rem;
  color: white;
  display: block;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 1rem;
}
</style>