
import {
  IonContent,
  IonMenuButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonButtons,
  IonLabel,
  IonSkeletonText,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Plugins } from "@capacitor/core";
import OnecastHeader from "@/components/Header.vue";
import OnecastPageTitle from "@/components/PageTitle.vue";
import QuicklinksService from "@/api/QuicklinksService.ts";

const { Browser } = Plugins;

export default defineComponent({
  name: "Settings",
  components: {
    IonContent,

    IonMenuButton,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonButtons,
    IonLabel,
    IonSkeletonText,

    OnecastHeader,
    OnecastPageTitle,
  },
  setup() {
    const quicklinks = ref([]);
    const quicklinksLoaded = ref(false);

    const getQuicklinks = async () => {
      await QuicklinksService.getQuicklinks().then((response) => {
        localStorage.setItem("quicklinks", JSON.stringify(response.data));

        quicklinks.value = response.data;
        quicklinksLoaded.value = true;
      });
    };

    onMounted(() => {
      getQuicklinks();
    });

    return {
      router: useRouter(),
      getQuicklinks,
      quicklinks,
      quicklinksLoaded,
    };
  },
  data() {
    return {};
  },
  methods: {
    openQuicklink(url: string) {
      Browser.open({
        url: url,
      });
    },
  },
});
