<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
          <!-- <ion-icon :icon="closeOutline" size="large"></ion-icon> -->
        </ion-buttons>

        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true"> Test </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Test",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonContent,
  },
  methods: {
    ionViewDidEnter() {
      console.log("Test did enter");
    },
    ionViewDidLeave() {
      console.log("Test did leave");
    },
    ionViewWillEnter() {
      console.log("Test will enter");
    },
    ionViewWillLeave() {
      console.log("Test will leave");
    },
  },
});
</script>

<style scoped>
</style>