<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
          <!-- <ion-icon :icon="closeOutline" size="large"></ion-icon> -->
        </ion-buttons>

        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <OnecastHeader></OnecastHeader>
      <div class="content-wrapper">
        <OnecastPageTitle>Quicklinks</OnecastPageTitle>

        <ion-list v-if="quicklinksLoaded && quicklinks.length">
          <ion-item
            v-for="quicklink in quicklinks"
            :key="quicklink.id"
            class="quicklink"
            @click="openQuicklink(quicklink.url)"
            detail="true"
          >
            <ion-label>{{ quicklink.name }}</ion-label>
          </ion-item>
        </ion-list>

        <ion-list v-if="!quicklinksLoaded">
          <ion-item class="quicklink">
            <ion-label
              ><ion-skeleton-text animated></ion-skeleton-text
            ></ion-label>
          </ion-item>
        </ion-list>
        <ion-list v-if="quicklinksLoaded && !quicklinks.length">
          <ion-item class="quicklink">
            <ion-label>No Quicklinks found</ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonMenuButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonButtons,
  IonLabel,
  IonSkeletonText,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Plugins } from "@capacitor/core";
import OnecastHeader from "@/components/Header.vue";
import OnecastPageTitle from "@/components/PageTitle.vue";
import QuicklinksService from "@/api/QuicklinksService.ts";

const { Browser } = Plugins;

export default defineComponent({
  name: "Settings",
  components: {
    IonContent,

    IonMenuButton,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonButtons,
    IonLabel,
    IonSkeletonText,

    OnecastHeader,
    OnecastPageTitle,
  },
  setup() {
    const quicklinks = ref([]);
    const quicklinksLoaded = ref(false);

    const getQuicklinks = async () => {
      await QuicklinksService.getQuicklinks().then((response) => {
        localStorage.setItem("quicklinks", JSON.stringify(response.data));

        quicklinks.value = response.data;
        quicklinksLoaded.value = true;
      });
    };

    onMounted(() => {
      getQuicklinks();
    });

    return {
      router: useRouter(),
      getQuicklinks,
      quicklinks,
      quicklinksLoaded,
    };
  },
  data() {
    return {};
  },
  methods: {
    openQuicklink(url: string) {
      Browser.open({
        url: url,
      });
    },
  },
});
</script>

<style scoped>
.content-wrapper {
  padding: 0 1em;
}
</style>