<template>
  <div>
    <div id="header_img"></div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
#header_img {
  min-height: 200px;
  max-height: 400px;
  height: 31vh;
  background: url("/assets/banner.png") center center / cover no-repeat;
}
</style>