
import {
  IonContent,
  IonRefresherContent,
  IonRefresher,
  IonMenuButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSkeletonText,
  loadingController,
  IonInfiniteScroll,
  IonIcon,
  IonImg,
  IonThumbnail,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import {
  add,
  closeOutline,
  menuOutline,
  chevronDownOutline,
  chevronDownCircleOutline,
  refresh,
} from "ionicons/icons";
import { defineComponent, ref, onMounted } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import MediaService from "@/api/MediaService.ts";
// import ImagesService from "@/api/ImagesService.ts";
import OnecastHeader from "@/components/Header.vue";
import OnecastPageTitle from "@/components/PageTitle.vue";
import OnecastFilters from "@/components/Filters.vue";

export default defineComponent({
  name: "Media",
  components: {
    IonContent,
    IonRefresherContent,
    IonRefresher,

    IonMenuButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSkeletonText,
    IonInfiniteScroll,
    IonIcon,
    IonImg,
    IonThumbnail,
    IonInfiniteScrollContent,
    OnecastHeader,
    OnecastPageTitle,
    OnecastFilters,
  },
  setup() {
    const contentFullscreen = ref(true);
    let localData = {
      files: [],
      channels: [],
      series: [],
      speakers: [],
    };
    try {
      localData = JSON.parse(localStorage.media);
    } catch (e) {
      //ignore
    }

    const data = ref(localData);
    const filesAll = ref(data.value.files);
    const files = ref(data.value.files);
    const filesLoaded = ref([]);
    const perPage = 10;
    const channels = ref(data.value.channels);
    const series = ref(data.value.series);
    const speakers = ref(data.value.speakers);

    const loadData = () => {
      const from = filesLoaded.value.length;
      const to = filesLoaded.value.length + perPage;
      filesLoaded.value = filesLoaded.value.concat(files.value.slice(from, to));
    };

    const getMediaFiles = async () => {
      await MediaService.getMedia({ limit: 0 }).then((response) => {
        localStorage.setItem("media", JSON.stringify(response.data));

        filesAll.value = response.data.files;
        files.value = response.data.files;
        channels.value = response.data.channels;
        series.value = response.data.series;
        speakers.value = response.data.speakers;

        loadData();
      });
    };

    // const getImagesFiles = async () => {
    //   console.log("fetch images started");
    //   await ImagesService.getImages({ limit: 0 }).then((response) => {
    //     console.log("fetch images complete");
    //     localStorage.setItem("images", JSON.stringify(response.data));
    //   });
    // };

    onMounted(async () => {
      loadData();
      await getMediaFiles();
    });

    return {
      router: useRouter(),
      contentFullscreen,
      add,
      closeOutline,
      menuOutline,
      chevronDownOutline,
      chevronDownCircleOutline,
      refresh,
      getMediaFiles,
      filesAll,
      files,
      filesLoaded,
      channels,
      series,
      speakers,
      loadData,
    };
  },
  data() {
    return {
      filtered: false,
      infiniteDisabled: false,
    };
  },
  methods: {
    async showLoading() {
      const loading = await loadingController.create({
        spinner: "dots",
        duration: 5000,
        message: "Click the backdrop to dismiss early...",
        translucent: true,
        cssClass: "custom-class custom-loading",
        backdropDismiss: true,
      });

      await loading.present();

      setTimeout(function () {
        loading.dismiss();
      }, 5000);
    },
    navigate(route: RouteLocationRaw) {
      this.router.push(route);
    },
    onFilterMedia(type: string, value: string) {
      this.filesLoaded = [];
      this.infiniteDisabled = false;
      if (type == "clear") {
        this.filtered = false;
        this.files = this.filesAll;
      } else {
        this.filtered = true;
        this.files = this.filesAll.filter((f) => {
          return f[type] == value;
        });
      }

      this.loadData();
    },
    async refreshFiles(showLoading: boolean) {
      this.onFilterMedia("clear", "");
      const loading = await loadingController.create({
        spinner: "dots",
        message: "Loading new content...",
        translucent: true,
        cssClass: "custom-class custom-loading",
        backdropDismiss: false,
      });

      if (showLoading) {
        await loading.present();
      }

      await this.getMediaFiles();

      if (showLoading) {
        setTimeout(function () {
          loading.dismiss();
        }, 1000);
      }
    },

    startRefresh() {
      this.contentFullscreen = false;

      const refresher = document.querySelector("ion-refresher");
      if (refresher !== null) {
        const refreshInterval = setInterval(async () => {
          if (this.contentFullscreen || (await refresher.getProgress()) == 0) {
            this.contentFullscreen = true;
            clearInterval(refreshInterval);
          }
        }, 300);
      }
    },

    async doRefresh(event: any) {
      await this.refreshFiles(false);
      this.contentFullscreen = true;
      event.target.complete();
    },

    infiniteLoad(event: any) {
      if (this.filesLoaded.length < this.files.length) {
        this.loadData();
        event.target.complete();
      } else {
        //event.target.disabled = true;
        this.infiniteDisabled = true;
      }
    },
  },
});
