
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Test",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonContent,
  },
  methods: {
    ionViewDidEnter() {
      console.log("Test did enter");
    },
    ionViewDidLeave() {
      console.log("Test did leave");
    },
    ionViewWillEnter() {
      console.log("Test will enter");
    },
    ionViewWillLeave() {
      console.log("Test will leave");
    },
  },
});
