<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/media"></ion-back-button>
        </ion-buttons>

        <ion-title>{{ fileInfo.name }}</ion-title>

        <ion-buttons slot="end">
          <ion-icon :icon="shareOutline" @click="shareMedia"></ion-icon>
          <ion-button @click="hideKeyboard" v-if="keyboardOpen"
            >Done</ion-button
          >
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <OnecastMedia
        ref="mediaPlayer"
        :thumbnail="fileInfo.thumbnail"
        :url="fileInfo.file"
        :playerType="fileInfo.playerType"
        :mediaType="fileInfo.type"
        :mimeType="fileInfo.mimeType"
      ></OnecastMedia>

      <div class="content-wrapper">
        <div class="media_info">
          <h1>{{ fileInfo.name }}</h1>
          <p>
            {{ fileInfo.speaker }}<br />
            {{ fileInfo.dateFormatted }}
          </p>
          <span class="media_type">{{ fileInfo.type }}</span>
        </div>
        <div class="description">
          {{ fileInfo.description }}
        </div>

        <div class="notes">
          <ion-item>
            <ion-label position="floating">Notes</ion-label>
            <ion-textarea
              auto-grow="true"
              autocapitalize="on"
              :value="fileNotes.notes"
              @ionChange="saveNotes"
            ></ion-textarea>
          </ion-item>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  IonContent,
  IonBackButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonTextarea,
  IonButtons,
  IonIcon,
  IonButton,
  IonLabel,
} from "@ionic/vue";
import { shareOutline } from "ionicons/icons";
import OnecastMedia from "@/components/MediaContainer.vue";

import { Plugins } from "@capacitor/core";
const { Keyboard, Share, Storage } = Plugins;

export default defineComponent({
  components: {
    IonContent,

    IonBackButton,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonTextarea,
    IonButtons,
    IonIcon,
    IonButton,
    IonLabel,
    OnecastMedia,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { mediaId } = route.params;
    const mediaPlayer = ref(null);
    const fileNotes = ref({
      id: "",
      title: "",
      notes: "",
      fileId: "",
      date: "",
      dateFormatted: "",
    });
    const keyboardOpen = ref(false);

    const fileInfo = ref({});
    const savedNotes = ref([]);

    try {
      fileInfo.value = JSON.parse(localStorage.getItem("media")).files.find(
        (f) => {
          return f.uniqueId == mediaId;
        }
      );
    } catch (e) {
      router.replace("/media");
    }

    const getNotes = async () => {
      const ret = await Storage.get({ key: "notes" });
      const value = JSON.parse(ret.value);
      if (value) {
        savedNotes.value = value;
      }

      savedNotes.value.find((n) => {
        if (n.fileId == mediaId) {
          fileNotes.value = n;
        }
      });

      if (fileNotes.value.id == "") {
        fileNotes.value.id = Number(new Date());
        fileNotes.value.date = fileInfo.value.date;
        fileNotes.value.dateFormatted = fileInfo.value.dateFormattedShort;
        fileNotes.value.fileId = mediaId;
        fileNotes.value.title = fileInfo.value.name;
        savedNotes.value.push(fileNotes.value);
      }
    };
    getNotes();

    // try {
    //   if (JSON.parse(localStorage.getItem("notes"))) {
    //     savedNotes = ref(JSON.parse(localStorage.getItem("notes")));
    //   }
    // } catch (e) {
    //   //ignore
    // }

    onMounted(() => {
      window.addEventListener("keyboardWillShow", () => {
        keyboardOpen.value = true;
      });
      window.addEventListener("keyboardWillHide", () => {
        keyboardOpen.value = false;
      });
    });

    return {
      shareOutline,
      mediaPlayer,
      fileInfo,
      savedNotes,
      fileNotes,
      keyboardOpen,
    };
  },
  data() {
    return {};
  },
  methods: {
    hideKeyboard() {
      Keyboard.hide();
    },
    async saveNotes(event) {
      this.fileNotes.notes = event.target.value;
      await Storage.set({
        key: "notes",
        value: JSON.stringify(this.savedNotes),
      });
    },
    async shareMedia() {
      await Share.share({
        title: this.fileInfo.name,
        text: `${this.fileInfo.name}\n\n`,
        url: this.fileInfo.player,
        dialogTitle: "Share",
      });
    },
  },
});
</script>
<style scoped>
ion-icon {
  font-size: 24px;
}

.content-wrapper {
  padding: 0 1em;
}

.media_info {
  position: relative;
}
.media_info .media_type {
  position: absolute;
  bottom: 0;
  right: 0;
  text-transform: capitalize;
  font-style: italic;
}
</style>